import React, { useContext } from 'react';
import Documento from './Documento';
import styled from 'styled-components';
import Plus from '../assets/maisbutton.png';

import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { AuthContext } from '../config/authContext';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
`;
const FileInputContainer = styled.label`
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  border-left: 5px solid grey;
  cursor: pointer;
`;

const HiddenFileInput = styled.input`
  display: none;
`;
const Arquivos = ({ setArquivo, ...props }) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      <Container>
        {props.dados == '' || !props.dados ? (
          ''
        ) : (
          <>
            {props.dados.docs.map((doc, index) => (
              <Documento key={index} url={doc} deletaDoc={props.deletaDoc}>
                {doc}
              </Documento>
            ))}
          </>
        )}

        <FileInputContainer>
          <img src={Plus} alt='' />
          <HiddenFileInput
            ref={props.ref}
            id='botao'
            type='file'
            accept='.docx, .pdf, .doc, .xlsx, .xls, .xlsm'
            onChange={(e) => setArquivo(e.target.files[0])}
          />
        </FileInputContainer>
      </Container>
    </>
  );
};

export default Arquivos;
