import React, { useContext, useEffect, useState } from 'react';
import { db, storage } from '../config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import Navbar from '../components/Navbar';
import styled from 'styled-components';

import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Arquivos from '../components/Arquivos';
import { useRef } from 'react';
import { AuthContext } from '../config/authContext';

const Titulo = styled.h1``;

const Texto = styled.p``;

const Input = styled.input``;

const Botao = styled.button``;

const ArquivosContainer = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 2%;
`;

const AuditoriaInterna = () => {
  const inputFileRef = useRef(null);
  const [arquivo, setArquivo] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dados, setDados] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (arquivo) {
      enviaArquivo();
    }
  }, [arquivo]);

  const currentDate = new Date()
    .toLocaleDateString('pt-BR')
    .replace(/\//g, '-');

  const getDados = async () => {
    const usersRef = doc(
      db,
      'usuarios',
      getAuth().currentUser.uid,
      'arquivos',
      'auditoria'
    );
    try {
      const data = await getDoc(usersRef).then((response) => {
        if (response.data()) {
          setDados(response.data());
          console.log(response.data());
        }
      });

      //   setUploading(false);
    } catch (error) {
      console.log(error);
    }
    setArquivo(null);
  };

  useEffect(() => {
    getDados();
  }, []);

  let novoArray2;

  const deletaDoc = async (url) => {
    const docRef = doc(
      db,
      'usuarios',
      currentUser.uid,
      'arquivos',
      'auditoria'
    );
    try {
      const data = await getDoc(docRef)
        .then((response) => {
          // console.log(response.data().docs);
          if (response.data()) {
            novoArray2 = response.data().docs.filter((item) => item !== url);
            console.log(novoArray);
          } else {
            novoArray2 = [url];
          }
          console.log(novoArray);
          setDoc(
            doc(db, 'usuarios', currentUser.uid, 'arquivos', 'auditoria'),
            {
              docs: novoArray2,
            }
          );
        })
        .then(() => getDados());
    } catch (error) {
      console.log(error);
    }
    // deleteDoc(docRef, url);
  };

  const enviaArquivo = (e) => {
    if (arquivo) {
      const storageRef = ref(storage, currentDate + `_${arquivo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, arquivo);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Progresso do upload (opcional)
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(`Progresso: ${progress}%`);
        },
        (error) => {
          // Tratamento de erros
          console.error('Erro no upload:', error);
          setUploading(false);
        },
        () => {
          // Upload completo
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log('Arquivo disponível em:', url);
            cadastrar(url);
          });
        }
      );
    } else {
      alert('Selecione um arquivo');
    }
  };

  const usersCollectionRef = collection(db, 'usuarios');
  let novoArray;

  const cadastrar = async (url) => {
    const usersRef = doc(
      db,
      'usuarios',
      getAuth().currentUser.uid,
      'arquivos',
      'auditoria'
    );

    try {
      const data = await getDoc(usersRef)
        .then((response) => {
          if (response.data()) {
            novoArray = [...response.data().docs, url];
          } else {
            novoArray = [url];
          }

          setDoc(
            doc(
              usersCollectionRef,
              getAuth().currentUser.uid,
              'arquivos',
              'auditoria'
            ),
            {
              id: getAuth().currentUser.uid,
              criadoEm: serverTimestamp(),
              docs: novoArray,
            },
            { merge: true }
          );
        })
        .then(() => getDados());
      inputFileRef.current.value = '';
      setUploading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Navbar />

      <Texto></Texto>
      <ArquivosContainer>
        <Arquivos
          enviaArquivo={enviaArquivo}
          setArquivo={setArquivo}
          ref={inputFileRef}
          dados={dados}
          deletaDoc={deletaDoc}
        />
      </ArquivosContainer>
    </>
  );
};

export default AuditoriaInterna;
