import { Button, TextField, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  /* align-items: center; */
  min-width: 400px;
  border: 0.5px solid var(--borda-texto);
  padding: 9px 5px;
  margin: 10px;
  border-radius: 5px;
  /* @media (max-width: 600px) {
    width: 100vw;
    flex-wrap: wrap;
    margin: 15px 10px;
    font-size: 18px;
    min-width: 100px;
  } */
  @media screen and (max-width: 960px) {
    width: 100%;
    min-width: auto;
  }
`;

const Rotulo = styled.label`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: ${(props) => (props.bold ? '600' : '400')};
  color: ${(props) =>
    props.textColor ? props.textColor : 'var(--azul-escuro-titulo)'};
  margin-bottom: ${(props) =>
    props.marginRotulo ? props.marginRotulo : '18px'};
  width: 100%;
  @media screen and (max-width: 960px) {
    font-size: 0.9em;
    width: 95%;
  }
`;

const InputContainer = styled.div`
  /* width: 100%; */
  border: 0.5px;
  width: 50%;
  @media screen and (max-width: 960px) {
    width: 90%;
  }
`;

const textFieldStyle = {
  width: '100%',
};

const CampoTexto = (props) => {
  return (
    <Container direction={props.direction}>
      <Rotulo
        bold={props.bold}
        marginRotulo={props.marginRotulo}
        textColor={props.textColor}
        htmlFor={props.Rótulo}
      >
        {props.label}
      </Rotulo>
      {/* <Input
        defaultValue={props?.defaultValue}
        required={props.required}
        placeholder={props.placeholder}
        type={props.type || "text"}
        maxLength={props.max}
        id={props.label}
        name={props.label}
        onChange={(e) => props.onChange(e)}
        value={props.value}
        disabled={props.disabled}
      /> */}
      <InputContainer>
        <TextField
          style={textFieldStyle}
          defaultValue={props?.defaultValue}
          required={props.required}
          placeholder={props.placeholder}
          type={props.type || 'text'}
          maxLength={props.max}
          idlabel={props.label}
          name={props.label}
          onChange={(e) => props.onChange(e)}
          value={props.value}
          disabled={props.disabled}
          id='outlined-basic'
          color='primary'
          // sx={{
          //   '& .MuiOutlinedInput-notchedOutline': {
          //     borderColor: '#150442',
          //   },
          // }}
          label={props.type == 'date' ? '' : 'Escreva'}
          variant='outlined'
        />
      </InputContainer>
    </Container>
  );
};

export default CampoTexto;
