import React from "react";
import Navbar from "../../components/Navbar";
import styled from "styled-components";
import SectionCard from "../../components/SectionCard";
import Logo from "../../assets/lgpdImg.png";

const MainContentContainer = styled.div`
  display: flex;
`;

const PinkBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  margin-left: 20px;
`;
const PinkBar = styled.div`
  width: 8px;
  height: 70vh;
  background-color: #be00ff;
  margin: 15px 0px;
`;
const TitleContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
`;
const Title = styled.p`
  font-size: 24px;
  color: #150442;
  margin: 0;
  margin-left: 10px;
`;
const MainCardContainer = styled.div`
  /* background-color: #e9e9e9; */
  margin-left: -30px;
  padding-top: 80px;
  /* padding-left: 60px; */
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
`;

const Avaliacao = () => {
  return (
    <div>
      <Navbar />
      <MainContentContainer>
        <div>
          <TitleContainer>
            <svg
              width="26"
              height="31"
              viewBox="0 0 26 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.11549 15.1186L25.693 30.1178L25.8939 0.467397L0.11549 15.1186Z"
                fill="#5B0390"
              />
            </svg>
            <Title>Avaliação</Title>
          </TitleContainer>
          <PinkBarContainer>
            <PinkBar></PinkBar>
            <img src={Logo} alt="" />
          </PinkBarContainer>
        </div>
        <MainCardContainer>
          <SectionCard path={"secao9"} title="SEÇÃO 9"></SectionCard>
        </MainCardContainer>
      </MainContentContainer>
    </div>
  );
};

export default Avaliacao;
