import 'styled-components';
import '../index.css';
import styled from 'styled-components';
import ISO from '../assets/ISO.svg';
import Logo from '../assets/Logo.svg';
import { AuthContext } from '../config/authContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  background-color: #150442;
  padding: 0 20px;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
`;

const ContainerImagem = styled.div`
  display: flex;
  align-items: center;
`;

const ISOLogo = styled.img`
  position: relative;
`;

const LogoIso = styled.img`
  position: relative;
  padding-right: 10px;
`;

const Lista = styled.ul`
  display: flex;
  list-style: none;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  height: 80px;
  margin: 0 15px;
  gap: 30px;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 1.2rem;
  justify-content: space-between;
`;

const ItemLista = styled.li`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 15px;
  &:hover {
    background-color: #5b0390;
    border-radius: 4px;
  }
`;

const Navbar = () => {
  const { sair } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await sair();
      console.log('Logout');
      navigate('/');
      // O logout foi bem-sucedido, você pode redirecionar o usuário ou realizar outras ações necessárias.
    } catch (error) {
      console.error('Erro ao fazer logout:', error.message);
    }
  };

  return (
    <Container>
      <ContainerImagem>
        <LogoIso src={Logo} />
        <ISOLogo src={ISO} />
      </ContainerImagem>
      <Lista>
        <ItemLista onClick={() => navigate('/')}>Home</ItemLista>
        <ItemLista onClick={() => navigate('/documentos')}>
          Documentos
        </ItemLista>
        <ItemLista onClick={() => navigate('/controlestecnicos')}>
          Controles Técnicos
        </ItemLista>
        <ItemLista onClick={() => navigate('/checklist')}>
          Checklist LGPD
        </ItemLista>
        <ItemLista onClick={() => navigate('/auditoriainterna')}>
          Auditoria Interna
        </ItemLista>

        {currentUser ? <ItemLista onClick={handleLogout}>Sair</ItemLista> : ''}
      </Lista>
    </Container>
  );
};

export default Navbar;
