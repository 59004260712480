import React, { useContext, useEffect, useRef, useState } from 'react';
import Navbar from '../../components/Navbar';
import styled from 'styled-components';
import SectionCard from '../../components/SectionCard';
import Logo from '../../assets/lgpdImg.png';
import { db, storage } from '../../config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import Plus from '../../assets/maisbutton.png';
import Arquivos from '../../components/Arquivos';
import { AuthContext } from '../../config/authContext';

const MainContentContainer = styled.div`
  display: flex;
`;

const Texto = styled.p``;

const PinkBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: fit-content;
  margin-left: 20px;
`;
const PinkBar = styled.div`
  width: 8px;
  height: 70vh;
  background-color: #be00ff;
  margin: 15px 0px;
`;
const TitleContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
`;
const Title = styled.p`
  font-size: 24px;
  color: #150442;
  margin: 0;
  margin-left: 10px;
  white-space: nowrap;
`;
const MainCardContainer = styled.div`
  /* background-color: #e9e9e9; */
  margin-left: -100px;
  padding-top: 80px;
  /* padding-left: 60px; */
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
`;

const Secao7 = () => {
  const inputFileRef = useRef(null);
  const [arquivo, setArquivo] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dados, setDados] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const currentDate = new Date()
    .toLocaleDateString('pt-BR')
    .replace(/\//g, '-');

  let novoArray2;
  const deletaDoc = async (url) => {
    const docRef = doc(
      db,
      'usuarios',
      currentUser.uid,
      'arquivos',
      'secoes',
      'secoes7',
      'arquivos'
    );
    try {
      const data = await getDoc(docRef)
        .then((response) => {
          // console.log(response.data().docs);
          if (response.data()) {
            novoArray2 = response.data().docs.filter((item) => item !== url);
            console.log(novoArray);
          } else {
            novoArray2 = [url];
          }
          console.log(novoArray);
          setDoc(
            doc(
              db,
              'usuarios',
              currentUser.uid,
              'arquivos',
              'secoes',
              'secoes7',
              'arquivos'
            ),
            {
              docs: novoArray2,
            }
          );
        })
        .then(() => getDados());
    } catch (error) {
      console.log(error);
    }
    // deleteDoc(docRef, url);
  };

  const getDados = async () => {
    const usersRef = doc(
      db,
      'usuarios',
      getAuth().currentUser.uid,
      'arquivos',
      'secoes',
      'secoes7',
      'arquivos'
    );
    try {
      const data = await getDoc(usersRef).then((response) => {
        if (response.data()) {
          setDados(response.data());
          console.log(response.data());
        }
      });

      //   setUploading(false);
    } catch (error) {
      console.log(error);
    }
    setArquivo(null);
  };

  const ArquivosContainer = styled.div`
    display: flex;
    margin-left: 5%;
    margin-top: 2%;
  `;

  useEffect(() => {
    getDados();
  }, []);

  useEffect(() => {
    if (arquivo) {
      enviaArquivo();
    }
  }, [arquivo]);

  const enviaArquivo = (e) => {
    if (arquivo) {
      const storageRef = ref(storage, currentDate + `_${arquivo.name}`);
      const uploadTask = uploadBytesResumable(storageRef, arquivo);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Progresso do upload (opcional)
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log(`Progresso: ${progress}%`);
        },
        (error) => {
          // Tratamento de erros
          console.error('Erro no upload:', error);
          setUploading(false);
        },
        () => {
          // Upload completo
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log('Arquivo disponível em:', url);
            cadastrar(url);
          });
        }
      );
    } else {
      alert('Selecione um arquivo');
    }
  };

  const usersCollectionRef = collection(db, 'usuarios');
  let novoArray;

  const cadastrar = async (url) => {
    const usersRef = doc(
      db,
      'usuarios',
      getAuth().currentUser.uid,
      'arquivos',
      'secoes',
      'secoes7',
      'arquivos'
    );

    try {
      const data = await getDoc(usersRef)
        .then((response) => {
          if (response.data()) {
            novoArray = [...response.data().docs, url];
          } else {
            novoArray = [url];
          }

          setDoc(
            doc(
              usersCollectionRef,
              getAuth().currentUser.uid,
              'arquivos',
              'secoes',
              'secoes7',
              'arquivos'
            ),
            {
              id: getAuth().currentUser.uid,
              criadoEm: serverTimestamp(),
              docs: novoArray,
            },
            { merge: true }
          );
        })
        .then(() => getDados());

      inputFileRef.current.value = '';
      setUploading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const backgroundImageUrl = `url(${Plus})`;

  return (
    <div>
      <Navbar />
      <MainContentContainer>
        <div>
          <TitleContainer>
            <svg
              width='26'
              height='31'
              viewBox='0 0 26 31'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.11549 15.1186L25.693 30.1178L25.8939 0.467397L0.11549 15.1186Z'
                fill='#5B0390'
              />
            </svg>
            <Title>Execução / Seção 7</Title>
          </TitleContainer>
          <PinkBarContainer>
            <PinkBar></PinkBar>
            <img src={Logo} alt='logo Lgpd' />
          </PinkBarContainer>
        </div>
        <MainCardContainer>
          {' '}
          <Texto></Texto>
          <ArquivosContainer>
            <Arquivos
              enviaArquivo={enviaArquivo}
              setArquivo={setArquivo}
              ref={inputFileRef}
              dados={dados}
              deletaDoc={deletaDoc}
            />
          </ArquivosContainer>
        </MainCardContainer>
      </MainContentContainer>
    </div>
  );
};

export default Secao7;
