import React, { useState } from 'react';
import styled from 'styled-components';
import './CheckList.css';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../config/firebase';

import Navbar from '../components/Navbar';
import CampoTexto from '../components/CampoTexto';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const Titulo = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 1% auto 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const StyledTable = styled.table`
  width: 100%;
  /* border-collapse: collapse; */
  border: 1px solid #ddd;
  font-size: 15px;
  border-spacing: 15px;
  thead tr {
    box-shadow: none;
  }
`;

const StyledTh = styled.th`
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  padding: 10px;
  background-color: purple;
  box-shadow: 0px 0px 19px -1px rgba(204, 0, 187, 0.65);

  color: white;
`;

const StyledTd = styled.td`
  padding: 2px;
  border-right: 1px solid #c9c9c9;
`;

const StyledTr = styled.tr`
  border: 12px solid #cc00bb;
  border-radius: 10px;
  box-shadow: 0 0 4px #acacac;
  & :last-child {
    border: none;
    text-align: center;
  }
`;

const Botao = styled.button`
  text-decoration: none;

  display: block;
  text-align: center;
  padding: 15px 5px;
  background: rgb(91, 3, 144);
  background: linear-gradient(90deg, #840dce 0%, #f71bff 100%);
  /* -webkit-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1);
  -moz-box-shadow: 0px 0px 85px -10px rgba(255, 76, 255, 1); */
  /* box-shadow: 0px 0px 65px -10px rgba(255, 76, 255, 1); */
  color: white;
  border-radius: 10px;
  border: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: 600;
  margin: 0 auto;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    scale: 1.03;
  }

  &[disabled] {
    background-color: #6c757d;
    background: #6c757d;
    cursor: not-allowed;
  }
`;

const CheckList = () => {
  const navigate = useNavigate();
  const [sucesso, setSucesso] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nomeMapeamentoDados, setNomeMapeamentoDados] = useState();
  const [emailMapeamentoDados, setEmailMapeamentoDados] = useState();
  const [primeiraDataMapeamentoDados, setPrimeiraDataMapeamentoDados] =
    useState();
  const [segundaDataMapeamentoDados, setSegundaDataMapeamentoDados] =
    useState();
  const [
    enviadoPrimeiraDataMapeamentoDados,
    setEnviadoPrimeiraDataMapeamentoDados,
  ] = useState(false);
  const [
    enviadoSegundaDataMapeamentoDados,
    setEnviadoSegundaDataMapeamentoDados,
  ] = useState(false);

  const [nomeAvaliacaoRiscos, setNomeAvaliacaoRiscos] = useState();
  const [emailAvaliacaoRiscos, setEmailAvaliacaoRiscos] = useState();
  const [primeiraDataAvaliacaoRiscos, setPrimeiraDataAvaliacaoRiscos] =
    useState();
  const [segundaDataAvaliacaoRiscos, setsegundaDataAvaliacaoRiscos] =
    useState();
  const [
    enviadoPrimeiraDataAvaliacaoRiscos,
    setEnviadoPrimeiraDataAvaliacaoRiscos,
  ] = useState(false);
  const [
    enviadoSegundaDataAvaliacaoRiscos,
    setEnviadoSegundaDataAvaliacaoRiscos,
  ] = useState(false);

  const [nomeTreinamentoFuncionarios, setNomeTreinamentoFuncionarios] =
    useState();
  const [emailTreinamentoFuncionarios, setEmailTreinamentoFuncionarios] =
    useState();
  const [
    primeiraDataTreinamentoFuncionarios,
    setPrimeiraDataTreinamentoFuncionarios,
  ] = useState();
  const [
    segundaDataTreinamentoFuncionarios,
    setSegundaDataTreinamentoFuncionarios,
  ] = useState();
  const [
    enviadoPrimeiraDataTreinamentoFuncionarios,
    setenviadoPrimeiraDataTreinamentoFuncionarios,
  ] = useState(false);
  const [
    enviadoSegundaDataTreinamentoFuncionarios,
    setEnviadoSegundaDataTreinamentoFuncionarios,
  ] = useState(false);

  const [nomeAuditoriaFornecedores, setNomeAuditoriaFornecedores] = useState();
  const [emailAuditoriaFornecedores, setEmailAuditoriaFornecedores] =
    useState();
  const [
    primeiraDataAuditoriaFornecedores,
    setPrimeiraDataAuditoriaFornecedores,
  ] = useState();
  const [
    segundaDataAuditoriaFornecedores,
    setSegundaDataAuditoriaFornecedores,
  ] = useState();
  const [
    enviadoPrimeiraDataAuditoriaFornecedores,
    setEnviadoPrimeiraDataAuditoriaFornecedores,
  ] = useState(false);
  const [
    enviadoSegundaDataAuditoriaFornecedores,
    setEnviadoSegundaDataAuditoriaFornecedores,
  ] = useState(false);

  const [nomeAtualizacaoPoliticas, setNomeAtualizacaoPoliticas] = useState();
  const [emailAtualizacaoPoliticas, setEmailAtualizacaoPoliticas] = useState();
  const [
    primeiraDataAtualizacaoPoliticas,
    setPrimeiraDataAtualizacaoPoliticas,
  ] = useState();
  const [segundaDataAtualizacaoPoliticas, setSegundaDataAtualizacaoPoliticas] =
    useState();
  const [
    enviadoPrimeiraDataAtualizacaoPoliticas,
    setEnviadoPrimeiraDataAtualizacaoPoliticas,
  ] = useState(false);
  const [
    enviadoSegundaDataAtualizacaoPoliticas,
    setEnviadoSegundaDataAtualizacaoPoliticas,
  ] = useState(false);

  const [nomeConsentimentos, setNomeConsentimentos] = useState();
  const [emailConsentimentos, setEmailConsentimentos] = useState();
  const [primeiraDataConsentimentos, setPrimeiraDataConsentimentos] =
    useState();
  const [segundaDataConsentimentos, setSegundaDataConsentimentos] = useState();
  const [
    enviadoPrimeiraDataConsentimentos,
    setEnviadoPrimeiraDataConsentimentos,
  ] = useState(false);
  const [
    enviadoSegundaDataConsentimentos,
    setEnviadoSegundaDataConsentimentos,
  ] = useState(false);

  const [nomeRespostaIncidentes, setNomeRespostaIncidentes] = useState();
  const [emailRespostaIncidentes, setEmailRespostaIncidentes] = useState();
  const [primeiraDataRespostaIncidentes, setPrimeiraDataRespostaIncidentes] =
    useState();
  const [segundaDataRespostaIncidentes, setSegundaDataRespostaIncidentes] =
    useState();
  const [
    enviadoPrimeiraDataRespostaIncidentes,
    setEnviadoPrimeiraDataRespostaIncidentes,
  ] = useState(false);
  const [
    enviadoSegundaDataRespostaIncidentes,
    setEnviadoSegundaDataRespostaIncidentes,
  ] = useState(false);

  const [nomeRequisicoesTitulares, setNomeRequisicoesTitulares] = useState();
  const [emailRequisicoesTitulares, setEmailRequisicoesTitulares] = useState();
  const [
    primeiraDataRequisicoesTitulares,
    setPrimeiraDataRequisicoesTitulares,
  ] = useState();
  const [segundaDataRequisicoesTitulares, setSegundaDataRequisicoesTitulares] =
    useState();
  const [
    enviadoPrimeiraDataRequisicoesTitulares,
    setEnviadoPrimeiraDataRequisicoesTitulares,
  ] = useState(false);
  const [
    enviadoSegundaDataRequisicoesTitulares,
    setEnviadoSegundaDataRequisicoesTitulares,
  ] = useState(false);

  const docRef = collection(db, 'usuarios');

  const cadastrar = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          nomeMapeamentoDados: nomeMapeamentoDados,
          emailMapeamentoDados: emailMapeamentoDados,
          primeiraDataMapeamentoDados: primeiraDataMapeamentoDados,
          segundaDataMapeamentoDados: segundaDataMapeamentoDados,
          enviadoPrimeiraDataMapeamentoDados:
            enviadoPrimeiraDataMapeamentoDados,
          enviadoSegundaDataMapeamentoDados: enviadoSegundaDataMapeamentoDados,

          nomeAvaliacaoRiscos: nomeAvaliacaoRiscos,
          emailAvaliacaoRiscos: emailAvaliacaoRiscos,
          primeiraDataAvaliacaoRiscos: primeiraDataAvaliacaoRiscos,
          segundaDataAvaliacaoRiscos: segundaDataAvaliacaoRiscos,
          enviadoPrimeiraDataAvaliacaoRiscos:
            enviadoPrimeiraDataAvaliacaoRiscos,
          enviadoSegundaDataAvaliacaoRiscos: enviadoSegundaDataAvaliacaoRiscos,

          nomeTreinamentoFuncionarios: nomeTreinamentoFuncionarios,
          emailTreinamentoFuncionarios: emailTreinamentoFuncionarios,
          primeiraDataTreinamentoFuncionarios:
            primeiraDataTreinamentoFuncionarios,
          segundaDataTreinamentoFuncionarios:
            segundaDataTreinamentoFuncionarios,
          enviadoPrimeiraDataTreinamentoFuncionarios:
            enviadoPrimeiraDataTreinamentoFuncionarios,
          enviadoSegundaDataTreinamentoFuncionarios:
            enviadoSegundaDataTreinamentoFuncionarios,

          nomeAuditoriaFornecedores: nomeAuditoriaFornecedores,
          emailAuditoriaFornecedores: emailAuditoriaFornecedores,
          primeiraDataAuditoriaFornecedores: primeiraDataAuditoriaFornecedores,
          segundaDataAuditoriaFornecedores: segundaDataAuditoriaFornecedores,
          enviadoPrimeiraDataAuditoriaFornecedores:
            enviadoPrimeiraDataAuditoriaFornecedores,
          enviadoSegundaDataAuditoriaFornecedores:
            enviadoSegundaDataAuditoriaFornecedores,

          nomeAtualizacaoPoliticas: nomeAtualizacaoPoliticas,
          emailAtualizacaoPoliticas: emailAtualizacaoPoliticas,
          primeiraDataAtualizacaoPoliticas: primeiraDataAtualizacaoPoliticas,
          segundaDataAtualizacaoPoliticas: segundaDataAtualizacaoPoliticas,
          enviadoPrimeiraDataAtualizacaoPoliticas:
            enviadoPrimeiraDataAtualizacaoPoliticas,
          enviadoSegundaDataAtualizacaoPoliticas:
            enviadoSegundaDataAtualizacaoPoliticas,

          nomeConsentimentos: nomeConsentimentos,
          emailConsentimentos: emailConsentimentos,
          primeiraDataConsentimentos: primeiraDataConsentimentos,
          segundaDataConsentimentos: segundaDataConsentimentos,
          enviadoPrimeiraDataConsentimentos: enviadoPrimeiraDataConsentimentos,
          enviadoSegundaDataConsentimentos: enviadoSegundaDataConsentimentos,

          nomeRespostaIncidentes: nomeRespostaIncidentes,
          emailRespostaIncidentes: emailRespostaIncidentes,
          primeiraDataRespostaIncidentes: primeiraDataRespostaIncidentes,
          // segundaDataRespostaIncidentes: segundaDataRespostaIncidentes,
          enviadoPrimeiraDataRespostaIncidentes:
            enviadoPrimeiraDataRespostaIncidentes,
          enviadoSegundaDataRespostaIncidentes:
            enviadoSegundaDataRespostaIncidentes,

          nomeRequisicoesTitulares: nomeRequisicoesTitulares,
          emailRequisicoesTitulares: emailRequisicoesTitulares,
          primeiraDataRequisicoesTitulares: primeiraDataRequisicoesTitulares,
          // segundaDataRequisicoesTitulares: segundaDataRequisicoesTitulares,
          enviadoPrimeiraDataRequisicoesTitulares:
            enviadoPrimeiraDataRequisicoesTitulares,
          enviadoSegundaDataRequisicoesTitulares:
            enviadoSegundaDataRequisicoesTitulares,
        },
        { merge: true }
      ).then(setSucesso(true), setIsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        <Titulo>CHECKLIST LGPD</Titulo>
        <Loading isLoading={isLoading} />
        {!sucesso ? (
          <form onSubmit={cadastrar}>
            <StyledTable>
              <thead>
                <StyledTr>
                  <StyledTh>Processo</StyledTh>
                  <StyledTh>Descrição</StyledTh>
                  <StyledTh>Responsável</StyledTh>
                  <StyledTh>Frequência</StyledTh>
                  <StyledTh>Documento</StyledTh>
                </StyledTr>
              </thead>
              <tbody>
                <StyledTr>
                  <StyledTd>Mapeamento de Dados</StyledTd>
                  <StyledTd>
                    Identificar onde e como os dados pessoais são coletados,
                    processados e armazenados.
                  </StyledTd>
                  <StyledTd>
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) => setNomeMapeamentoDados(e.target.value)}
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) => setEmailMapeamentoDados(e.target.value)}
                    />
                  </StyledTd>
                  <StyledTd>
                    A cada seis meses
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Primeira Data'
                      onChange={(e) =>
                        setPrimeiraDataMapeamentoDados(e.target.value)
                      }
                    />
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Segunda Data'
                      onChange={(e) =>
                        setSegundaDataMapeamentoDados(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Mapa de dados; Políticas de privacidade
                    <Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>

                <StyledTr>
                  <StyledTd>Avaliação de Riscos</StyledTd>
                  <StyledTd>
                    Avaliar riscos associados ao tratamento de dados pessoais.
                  </StyledTd>
                  <StyledTd>
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) => setNomeAvaliacaoRiscos(e.target.value)}
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) => setEmailAvaliacaoRiscos(e.target.value)}
                    />
                  </StyledTd>
                  <StyledTd>
                    A cada seis meses
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Primeira Data'
                      onChange={(e) =>
                        setPrimeiraDataAvaliacaoRiscos(e.target.value)
                      }
                    />
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Segunda Data'
                      onChange={(e) =>
                        setsegundaDataAvaliacaoRiscos(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Matriz de Risco
                    <Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>

                <StyledTr>
                  <StyledTd>Treinamento de Funcionários</StyledTd>
                  <StyledTd>
                    Capacitar os funcionários sobre práticas de proteção de
                    dados e conformidade com a LGPD.
                  </StyledTd>
                  <StyledTd>
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) =>
                        setNomeTreinamentoFuncionarios(e.target.value)
                      }
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) =>
                        setEmailTreinamentoFuncionarios(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    A cada seis meses
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Primeira Data'
                      onChange={(e) =>
                        setPrimeiraDataTreinamentoFuncionarios(e.target.value)
                      }
                    />
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Segunda Data'
                      onChange={(e) =>
                        setSegundaDataTreinamentoFuncionarios(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Certificados<Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>

                <StyledTr>
                  <StyledTd>Auditoria de Fornecedores</StyledTd>
                  <StyledTd>
                    Verificar a conformidade dos fornecedores e parceiros com a
                    LGPD.
                  </StyledTd>
                  <StyledTd>
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) =>
                        setNomeAuditoriaFornecedores(e.target.value)
                      }
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) =>
                        setEmailAuditoriaFornecedores(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    A cada seis meses
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Primeira Data'
                      onChange={(e) =>
                        setPrimeiraDataAuditoriaFornecedores(e.target.value)
                      }
                    />
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Segunda Data'
                      onChange={(e) =>
                        setSegundaDataAuditoriaFornecedores(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Aditivos contratuais
                    <Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>

                <StyledTr>
                  <StyledTd>Atualização de Políticas</StyledTd>
                  <StyledTd>
                    Revisar e atualizar as políticas de privacidade e proteção
                    de dados.
                  </StyledTd>
                  <StyledTd>
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) =>
                        setNomeAtualizacaoPoliticas(e.target.value)
                      }
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) =>
                        setEmailAtualizacaoPoliticas(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    A cada seis meses
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Primeira Data'
                      onChange={(e) =>
                        setPrimeiraDataAtualizacaoPoliticas(e.target.value)
                      }
                    />
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Segunda Data'
                      onChange={(e) =>
                        setSegundaDataAtualizacaoPoliticas(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Política de privacidade Política de segurança
                    <Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>

                <StyledTr>
                  <StyledTd>Gerenciamento de Consentimentos</StyledTd>
                  <StyledTd>
                    Gerenciar o armazenamento e atualização dos consentimentos
                    dos titulares de dados.
                  </StyledTd>
                  <StyledTd>
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) => setNomeConsentimentos(e.target.value)}
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) => setEmailConsentimentos(e.target.value)}
                    />
                  </StyledTd>
                  <StyledTd>
                    A cada seis meses
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Primeira Data'
                      onChange={(e) =>
                        setPrimeiraDataConsentimentos(e.target.value)
                      }
                    />
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Segunda Data'
                      onChange={(e) =>
                        setSegundaDataConsentimentos(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Registro de consentimento
                    <Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>

                <StyledTr>
                  <StyledTd>Resposta a Incidentes</StyledTd>
                  <StyledTd>
                    Implementar e testar o plano de resposta a incidentes de
                    segurança de dados.
                  </StyledTd>
                  <StyledTd>
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) =>
                        setNomeRespostaIncidentes(e.target.value)
                      }
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) =>
                        setEmailRespostaIncidentes(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Mensalmente - Verificar se houve incidente de segurança e
                    fazer a notificação à ANPD
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Próxima Data'
                      onChange={(e) =>
                        setPrimeiraDataRespostaIncidentes(e.target.value)
                      }
                    />
                    {/* <CampoTexto
                  type={'date'}
                  required={true}
                  label='Segunda Data'
                  onChange={(e) =>
                    setSegundaDataRespostaIncidentes(e.target.value)
                  }
                /> */}
                  </StyledTd>
                  <StyledTd>
                    Formulário de notificação da ANPD
                    <Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>

                <StyledTr>
                  <StyledTd>Requisições dos Titulares</StyledTd>
                  <StyledTd>
                    Atender a solicitações de acesso, correção, exclusão etc.,
                    dos titulares de dados.
                  </StyledTd>
                  <StyledTd>
                    {' '}
                    <CampoTexto
                      required={true}
                      label='Nome: '
                      onChange={(e) =>
                        setNomeRequisicoesTitulares(e.target.value)
                      }
                    />
                    <CampoTexto
                      required={true}
                      type='email'
                      label='Email: '
                      onChange={(e) =>
                        setEmailRequisicoesTitulares(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Mensalmente-Verificar mensalmente- o prazo de resposta aos
                    titulares é de 15 dias
                    <CampoTexto
                      type={'date'}
                      required={true}
                      label='Próxima Data'
                      onChange={(e) =>
                        setPrimeiraDataRequisicoesTitulares(e.target.value)
                      }
                    />
                  </StyledTd>
                  <StyledTd>
                    Registros de solicitações dos titulares
                    <Botao disabled='true'>Enviar Documento</Botao>
                  </StyledTd>
                </StyledTr>
              </tbody>
            </StyledTable>
            <Botao>Salvar</Botao>
          </form>
        ) : (
          <>
            <p>Sucesso</p>
            <Botao onClick={() => navigate('/')}>Voltar para Home</Botao>
          </>
        )}
      </Container>
    </>
  );
};

export default CheckList;
