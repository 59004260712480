import React from "react";
import styled from "styled-components";
import ImgFolder from "../assets/folderImg.png";
import { useNavigate } from "react-router-dom";

const MainContainer = styled.div`
  max-width: 200px;
  background-color: #150442;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 12px 0px;
  padding-left: 20px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  &:hover {
    scale: 1.08;
  }
`;
const Title = styled.p`
  color: white;
  font-size: 32px;
  margin: 0;
`;
const FolderImg = styled.img`
  margin-right: 15px;
`;

const SectionCard = (props) => {
  const navigate = useNavigate();
  return (
    <MainContainer onClick={() => navigate(`/${props.path}`)}>
      <FolderImg src={ImgFolder} />
      <Title>{props.title}</Title>
    </MainContainer>
  );
};

export default SectionCard;
