import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { AuthContext } from '../config/authContext';
import styled, { keyframes } from 'styled-components';
import Setas from '../assets/setasroxas.svg';
import DOC from '../assets/documento.svg';
import { useNavigate } from "react-router-dom";
import { db, storage } from '../config/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Arquivos from '../components/Arquivos';
import Documento from '../components/Documento';


const Wrapper = styled.div`
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ContainerEsquerdo = styled.div`
  background-size: 90%;

  flex: 1;
  width: 60%;
  height: 90%;
  font-family: 'Montserrat';

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25%;
  position: relative;
  &::before {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${Setas});
    background-size: contain;
    animation: ${rotateAnimation} 30s linear infinite;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; // Garante que o fundo fique atrás do conteúdo
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
  }
  button {
    width: fit-content;
    height: fit-content;
    background-color: #ffffff;
  }
  #bt3 {
    margin-right: 100px;
  }

  #bt4 {
    margin-left: 100px;
  }
`;

const Divisor = styled.div`
  width: 6px;
  min-width: 6px;
  background-color: #c5c5c5;
  height: 80%;
  margin: 0 5%;
`;

const TituloDireita = styled.h1`
  display: flex;
  flex-direction: row;
  font-size: 40px;
  font-weight: 500;
  color: #150442;
`;

const ContainerDireito = styled.div`
  display: flex;

  font-family: 'Montserrat';
  flex-direction: column;
  width: 40%;
  margin-top: -100px;
  height: 70%;
`;

const Botao = styled.button`
  position: relative;
  border: none;
  background: none;
  font-size: 30px;
  font-weight: 400;
  color: #150442;
  transition: 0.1s ease-in-out;

  &:hover {
    scale: 1.1;
    cursor: pointer;
    border: 2px solid purple;
    border-radius: 10px;
  }
`;

const BotoesDireita = styled.div`
  font-family: 'Montserrat';
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  width: 70%;

`;

const RecentFile = styled.div`
  width: 160px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid purple;
  border-radius: 20px;
  transition: 0.1s ease-in-out;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &:hover {
    scale: 1.1;
    cursor: pointer;
  }
`;

const DocTitle = styled.span``;

const Docimg = styled.img`
  min-width: 65px;
  margin-bottom: 20px;
`;

const Home = (props) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(currentUser);
  const [arquivo, setArquivo] = useState(null);
  const [dados, setDados] = useState([]);
  const [recentFiles, setRecentFiles] = useState({});
  

  const getDados = async () => {
    const currentUser = getAuth().currentUser;
    const dadosArray = [];
  
    try {
      for (let i = 1; i <= 10; i++) {
        const usersRef = doc(
          db,
          'usuarios',
          currentUser.uid,
          'arquivos',
          'secoes',
          `secoes${i}`,
          'arquivos'
        );
  
        const response = await getDoc(usersRef);
        if (response.data()) {
          dadosArray.push(response.data().docs);
          console.log(response.data());
        }
      }
  
      setDados(dadosArray);
      console.log(dadosArray);
  
      // setUploading(false);
    } catch (error) {
      console.log(error);
    }
    setArquivo(null);
  };
  

  useEffect(() => {
    getDados();
  }, []);

  function capturarLinks(arrayDeArrays) {
    let linksCapturados = [];
    
    for (let subArray of arrayDeArrays) {
      if (subArray.length > 0) {
        // Captura o primeiro link do sub-array
        linksCapturados.push(subArray[0]);
      }
    }
    
    return linksCapturados;
  }
  



  useEffect(() => {
    setRecentFiles({
      docs: capturarLinks(dados)
    });
  }, [dados]);


  // =======
  //   display: flexbox;
  //   font-family: 'Montserrat';
  // `;

  // const Botao2 = styled.button`
  //   position: relative;
  //   flex-wrap: wrap;
  //   max-width: 50%;
  //   margin: 10px;
  //   height: 109px;
  //   width: 118px;
  //   top: 25%;
  //   right: 35.9%;
  //   border: solid 2px #5b0390;
  //   border-radius: 8px;
  //   background: none;
  //   background-image: url(${DOC});
  //   background-size: auto;
  //   font-family: 'Montserrat';
  //   font-weight: 500;
  //   font-size: 12px;
  //   color: #150442;
  //   padding-top: 10%;
  //   background-repeat: no-repeat;
  // `;

  // const Home = () => {

  return (
    <Wrapper>
      <Navbar />
      <Container>
        <ContainerEsquerdo>
          <div>
            <Botao onClick={() => navigate("/melhoria")} id="bt1">
              Melhoria
            </Botao>
          </div>
          <div>
            <Botao onClick={() => navigate("/planejamento")} id="bt2">
              Planejamento
            </Botao>
          </div>
          <div>
            <Botao onClick={() => navigate("/avaliacao")} id="bt3">
              Avaliação
            </Botao>
          </div>
          <div>
            <Botao onClick={() => navigate("/execucao")} id="bt4">
              Execução
            </Botao>
          </div>
        </ContainerEsquerdo>
        <Divisor></Divisor>
        <ContainerDireito>
          <TituloDireita>Arquivos Recentes</TituloDireita>
          <BotoesDireita>
            {recentFiles.docs ? recentFiles.docs.map((doc, index) => (
              index < 4 ? 
              <Documento dontDelete key={index} url={doc}>
                {doc}
              </Documento> : ""
            )) : ""}
            {}
      
          </BotoesDireita>
        </ContainerDireito>
      </Container>
    </Wrapper>
  );
};

export default Home;
