



import Plus from "../assets/maisbutton.png";
import React, { useContext } from 'react';
import styled from 'styled-components';
import docImg from '../assets/doc.svg';
import { useNavigate } from 'react-router-dom';
import { db } from '../config/firebase';
import { AuthContext } from '../config/authContext';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import lixoImg from '../assets/lixo.png';

const Container = styled.div`
  /* overflow: hidden; */
`;

const CaixaDoc = styled.div`
  /* border: ${(props) =>
    props.teste ? "4px solid gray" : "2px solid purple"}; */
  border: ${(props) =>
    props.teste ? "4px solid #acacac" : "2px solid purple"};

  border-radius: ${(props) => (props.teste ? "0px" : "20px")};
  border-style: ${(props) => (props.teste ? "solid" : null)};

  border-bottom: ${(props) => (props.teste ? "0px" : null)};
  border-right: ${(props) => (props.teste ? "0px" : null)};
  border-top: ${(props) => (props.teste ? "0px" : null)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  cursor: pointer;
  box-shadow: ${(props) =>
    props.teste
      ? "50%"
      : "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);"};
`;

const Titulo = styled.h1``;

const Texto = styled.p`
  color: purple;
  font-weight: 500;
  /* padding: 0 20px; */
`;

const Imagem = styled.img`
  width: ${(props) => (props.teste ? "50%" : "30%")};
`;

const Lixo = styled.div`
  position: relative;
  top: -200px;
  left: 163px;
  border-radius: 500px;
  background-color: purple;
  border: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    scale: 1.08;
  }

`

function truncateString(str) {
  // Verifica se o comprimento da string é maior que 15
  if (str.length > 15) {
    // Corta a string nos primeiros 15 caracteres e adiciona "..."
    return str.substring(0, 15) + "...";
  } else {
    // Retorna a string original se ela não for maior que 15 caracteres
    return str;
  }
}

const Documento = (props) => {
  const { currentUser } = useContext(AuthContext);
  console.log(props.url);

  const navigate = useNavigate();
  // Expressões regulares para extrair a data e o nome do arquivo
  const regexData = /\/(\d{2}-\d{2}-\d{4})/;
  const regexNomeArquivo = /_(.+)\?/;
  // Extrair a data
  const match = props.url.match(regexData);
  const data = match ? match[1] : "";

  // Extrair o nome do arquivo
  const nomeArquivoMatch = props.url.match(regexNomeArquivo);
  const nomeArquivo = nomeArquivoMatch ? nomeArquivoMatch[1] : "";

  return (
    <Container>

      <CaixaDoc
        teste={props.teste}
        onClick={() => window.open(props.url, "_blank")}
      >
        {props.teste ? (
          <Imagem teste={props.teste} src={props.teste ? Plus : docImg} />
        ) : (
          <>
            <Texto>Versão: {data}</Texto>
            <Imagem teste={props.teste} src={props.teste ? Plus : docImg} />
            <Texto> {truncateString(nomeArquivo)}</Texto>
          </>
        )}

      </CaixaDoc>
      {props.dontDelete ? "" :       <Lixo onClick={() => props.deletaDoc(props.url)}>
        <img style={{width: "70%"}} src={lixoImg} alt="" />
      </Lixo>}

    </Container>
  );
};

export default Documento;
