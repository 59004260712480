import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import "./App.css";
import { db } from "./config/firebase";


import CheckList from './pages/Checklist';
import Home from "./pages/Home";
import { AuthContext } from "./config/authContext";
import LandingPage from "./pages/LandingPage";
import { useContext } from "react";
import NotFound from "./pages/NotFound";
import Documentos from "./pages/Documentos";
import ControlesTecnicos from "./pages/ControlesTecnicos";
import AuditoriaInterna from "./pages/AuditoriaInterna";
import Planejamento from "./pages/pdca/Planejamento";
import Execucao from "./pages/pdca/Execucao";
import Melhoria from "./pages/pdca/Melhoria";
import Avaliacao from "./pages/pdca/Avaliacao";
import Navbar from "./components/Navbar";
import Secao4 from "./pages/sections/Secao4";
import Secao5 from "./pages/sections/Secao5";
import Secao6 from "./pages/sections/Secao6";
import Secao8 from "./pages/sections/Secao8";
import Secao7 from "./pages/sections/Secao7";
import Secao9 from "./pages/sections/Secao9";
import Secao10 from "./pages/sections/Secao10";


function App() {
  const { currentUser } = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        <Route path="/" element={currentUser ? <Home /> : <LandingPage />} />
        <Route
          path="/home"
          element={currentUser ? <Home /> : <LandingPage />}
        />
        <Route
          path="/controlestecnicos"
          element={currentUser ? <ControlesTecnicos /> : <LandingPage />}
        />
        <Route
          path='/checklist'
          element={currentUser ? <CheckList /> : <LandingPage />}
        />

        <Route
          path="/auditoriainterna"
          element={currentUser ? <AuditoriaInterna /> : <LandingPage />}
        />
        <Route
          path="/documentos"
          element={currentUser ? <Documentos /> : <LandingPage />}
        />
        <Route
          path="/planejamento"
          element={currentUser ? <Planejamento /> : <LandingPage />}
        />
        <Route
          path="/execucao"
          element={currentUser ? <Execucao /> : <LandingPage />}
        />
        <Route
          path="/avaliacao"
          element={currentUser ? <Avaliacao /> : <LandingPage />}
        />
        <Route
          path="/melhoria"
          element={currentUser ? <Melhoria /> : <LandingPage />}
        />
        <Route
          path="/secao4"
          element={currentUser ? <Secao4 /> : <LandingPage />}
        />
        <Route
          path="/secao5"
          element={currentUser ? <Secao5 /> : <LandingPage />}
        />
        <Route
          path="/secao6"
          element={currentUser ? <Secao6 /> : <LandingPage />}
        />
        <Route
          path="/secao7"
          element={currentUser ? <Secao7 /> : <LandingPage />}
        />
        <Route
          path="/secao8"
          element={currentUser ? <Secao8 /> : <LandingPage />}
        />
        <Route
          path="/secao9"
          element={currentUser ? <Secao9 /> : <LandingPage />}
        />
        <Route
          path="/secao10"
          element={currentUser ? <Secao10 /> : <LandingPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
